import { graphql } from "gatsby";
import React from "react";
import ContactForm from "../components/AllModules/ContactForm/ContactForm";
import FeaturedProperties from "../components/FeaturedProperties/FeaturedProperty";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import SEO from "../components/seo";
import TeamDetail from "../components/TeamDetails/TeamDetail";

const TeamTemplate = (props) => {
    return (
        <div>
            <SEO
                title={`${props?.data?.glstrapi?.team?.Name} ${props?.data?.glstrapi?.team?.Designation}`}
                description={`Get to know about ${props?.data?.glstrapi?.team?.Name} here. Contact one of our skilled real estate professionals for assistance in buying, selling or leasing properties.`}
                location={props.location}
            />
            <Header />
            <TeamDetail
                data={props?.data?.glstrapi?.team}
                properties={props?.data?.glstrapi?.properties}
                count_sale={props?.data?.glstrapi?.total_properties_sale?.aggregate?.count}
                count_rent={props?.data?.glstrapi?.total_properties_rent?.aggregate?.count}
            />

            {(props?.data?.glstrapi?.properties && props.data.glstrapi.properties.length > 0) &&
                <>
                    <hr />
                    <FeaturedProperties
                        properties={props?.data?.glstrapi?.properties}
                        data={{
                            Title: "My Properties"
                        }}
                        hasData={true}
                        email={props?.data?.glstrapi?.team?.Email}
                        id={props?.data?.glstrapi?.team?.id}
                    />
                </> 
            }
            <ContactForm
                email={props?.data?.glstrapi?.team?.Email}
            // page_type="career-detail"
            />
            <div className="meet-team-footer">
                <Footer
                    popularSearch="Popular_About"
                />
            </div>
        </div>
    )
}

export default TeamTemplate;

export const pageQuery = graphql`
    query teamQuery($id: ID!, $email: ID!) {
        glstrapi {
            team(id: $id, publicationState: LIVE) {
                id
                Name
                Meta_Title
                Meta_Description
                Tile_Image {
                    url
                    alternativeText
                }
                About
                Phone
                Email
                Designation
                BRN
                Area_of_Special
                Experience_From
                Instagram
                Languages_Spoken
                Linkedin
                URL
                Video_Url
                Whatsapp_Number
            }
            total_properties_sale: propertiesConnection(where: { negotiator_email: $email, publish: true, search_type: "sales"}) {
                aggregate {
                    count
                }
            }
            total_properties_rent: propertiesConnection(where: { negotiator_email: $email, publish: true, search_type: "lettings"}) {
                aggregate {
                    count
                }
            }
            properties(where: { negotiator_email: $email, publish: true }) {
                _id
                id
                display_address
                images
                price
                price_qualifier
                search_type
                slug
                floorarea_max
                floorarea_min
                floorarea_type
                floor_area
                building
                bedroom
                bathroom
                address
                imagetransforms
                max_price
                status
                title
                video_link
                virtual_tour
            }  
        }
    }
`