import { Link } from "gatsby";
import HTMLReactParser from "html-react-parser";
import React, { useState, useEffect } from "react";
import { isMobileOnly } from "react-device-detect";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row, Col, Modal, Breadcrumb } from "react-bootstrap";
import VideoCard from "../AllModules/VideoCard/VideoCard";
import ContactFormPage from "../forms/contact";
// import PlayVideo from "../Play/PlayVideo";
// import YouTube from "react-youtube";
import "./TeamDetails.scss";
// import { getVideoId } from "../utils";
import Vimeo from "@u-wave/react-vimeo";
import YouTube from "react-youtube";
import { getVideoId, removePlus } from "../utils";
import classNames from "classnames";

const TeamDetail = (props) => {
    const [isPlaying, setPlaying] = useState(false);
    const [emailModal, setEmailModal] = React.useState(false);

    useEffect(() => {
        handleNegotiatorEmail()
    }, [])

    const handleNegotiatorEmail = () =>{
        localStorage.setItem("nego_email", props?.data?.Email)
    }
    
    return (
        <div className="team-details">
            <Container fluid className="px-xl-5 px-lg-4 px-3">
                <Breadcrumb className="mb-0 pb-0">
                    <Breadcrumb.Item>
                        <Link to="/">
                            home
                        </Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        <Link to="/about-espace/meet-the-team/">
                            people
                        </Link>
                    </Breadcrumb.Item> 
                    <Breadcrumb.Item active>
                        {props?.data?.Name}
                    </Breadcrumb.Item> 
                    {/* <Breadcrumb.Item></Breadcrumb.Item>  */}
                </Breadcrumb>
            </Container>
            <Container className="mt-xl-5 mt-4">
                <Row>
                    <Col xl={4} xs={12} className="mb-xl-0 mb-4">
                        {/* <img src={props?.data?.Tile_Image?.url}/> */}
                        <div className={classNames("img-item w-100 px-0", {"video-inline-h": isPlaying})}>
                            <ScrollAnimation animateIn="fadeInUp" animateOnce>
                                <VideoCard
                                    cardImg={props?.data?.Tile_Image?.url}
                                    cardImgAlt={props?.data?.Name + ", " + props?.data?.Designation + " - Espace"}
                                />
                                {props?.data?.Video_Url && <strong
                                    className="play-btn"
                                    onClick={(e) => {
                                        setPlaying(!isPlaying);
                                    }}
                                >
                                    <i className={isPlaying ? "icon-close": "icon-play"}></i>
                                </strong>}
                                <div className="inline-video inline-video-container">
                                    {isPlaying && <YouTube
                                        className="inline-video"
                                        containerClassName="sa"
                                        videoId={getVideoId(props?.data?.Video_Url)}
                                        opts={{
                                            height: "100%",
                                            width: "100%",
                                            playerVars: {
                                                autoplay: 1,
                                                disablekb: 1,
                                                showinfo: 0,
                                                title: 0,
                                                loop: 1,
                                                modestbranding: 0
                                            }
                                        }}
                                    />}
                                </div>
                            </ScrollAnimation>
                        </div>
                    </Col>
                    <Col xl={7} xs={12} className="ps-xl-5 ps-auto">
                        <h1>{props?.data?.Name}</h1>
                        <p>{props?.data?.Designation}</p>
                        <ul className="cards-btns d-flex align-items-center mb-xxl-0 mb-xl-4 mb-lg-3 mb-0">
                            <li>
                                <a href="javascript:;" onClick={() => setEmailModal(true)} className="btn subscribe-btn media-btn px-xl-5 px-lg-4 px-4 me-xl-4 me-2"><i className="icon-mail"></i><span className="">email</span></a>
                            </li>
                            <li>
                                <a href={`tel:${props?.data?.Phone}`} className="btn subscribe-btn media-btn px-xl-5 px-lg-4 px-4 me-xl-4 me-2"><i className="icon-mobile"></i><span className="d-none d-md-inline-block">call</span></a>
                            </li>
                            <li>
                                <a href={`https://api.whatsapp.com/send?phone=${removePlus(props?.data?.Phone)}`} target="_blank" className="btn subscribe-btn media-btn px-xl-5 px-lg-4 px-4"><i className="icon-whatsapp"></i><span className="d-none d-md-inline-block">whatsapp</span></a>
                            </li>
                        </ul>
                        {props.data && <div className="row desc-list">
                            {props.data.Area_of_Special && <React.Fragment>
                                <div className="col-lg-4 col-5">
                                    <ul className="desc-list pt-0">
                                        <li><span className="txt-desc txt-desc-text">Areas of Specialism:</span></li>
                                    </ul>
                                </div>
                                <div className="col-lg-7 col-7">
                                    <ul className="desc-list pt-0">
                                        <li><span className="desc-value">{props?.data?.Area_of_Special}</span></li> 
                                    </ul>
                                </div>
                            </React.Fragment>}
                            {props.data.Languages_Spoken && <React.Fragment>
                                <div className="col-lg-4 col-5">
                                    <ul className="desc-list pt-0">
                                        <li><span className="txt-desc txt-desc-text">Languages Spoken:</span></li>
                                    </ul>
                                </div>
                                <div className="col-lg-7 col-7">
                                    <ul className="desc-list pt-0">
                                        <li><span className="desc-value">{props?.data?.Languages_Spoken}</span></li>
                                    </ul>
                                </div>
                            </React.Fragment>}
                            {props.data.Experience_From && <React.Fragment>
                                <div className="col-lg-4 col-5">
                                    <ul className="desc-list pt-0">
                                        <li><span className="txt-desc txt-desc-text">Experience Since:</span></li>
                                    </ul>
                                </div>
                                <div className="col-lg-7 col-7">
                                    <ul className="desc-list pt-0">
                                        <li><span className="desc-value">{props?.data?.Experience_From}</span></li>
                                    </ul>
                                </div>
                            </React.Fragment>}
                            {props?.properties.length > 0 && <React.Fragment>
                                <div className="col-lg-4 col-5">
                                    <ul className="desc-list pt-0">
                                        <li><span className="txt-desc txt-desc-text">Active Listings:</span></li>
                                    </ul>
                                </div>
                                <div className="col-lg-7 col-7">
                                    <ul className="desc-list pt-0 property-count ">
                                        {
                                            props?.properties?.some(c => c.search_type === "sales") && 
                                            <li><Link to={`/properties-for-sale/in-dubai/?id:${props?.data?.id}`}><span className="desc-value clr-primary">{props?.count_sale} Sale Properties</span></Link></li>
                                        }
                                        {
                                            props?.properties?.some(c => c.search_type === "lettings") &&
                                            <li><Link to={`/properties-for-rent/in-dubai/?id:${props?.data?.id}`}><span className="desc-value clr-primary"> {props?.count_rent} Rent Properties</span></Link></li>
                                        }
                                    </ul>
                                </div>
                            </React.Fragment>}
                            {props.data.BRN && <React.Fragment>
                                <div className="col-lg-4 col-5">
                                    <ul className="desc-list pt-0">
                                        <li><span className="txt-desc txt-desc-text">BRN:</span></li>
                                    </ul>
                                </div>
                                <div className="col-lg-7 col-7">
                                    <ul className="desc-list pt-0">
                                        <li><span className="desc-value">{props?.data?.BRN}</span></li>
                                    </ul>
                                </div>
                            </React.Fragment>}
                            {props.data.Linkedin && <React.Fragment>
                                <div className="col-lg-4 col-5">
                                    <ul className="desc-list pt-0">
                                        <li><span className="txt-desc txt-desc-text">LinkedIn:</span></li>
                                    </ul>
                                </div>
                                <div className="col-lg-7 col-7">
                                    <ul className="desc-list pt-0">
                                        <li><span className="desc-value"><a href={props?.data?.Linkedin} target="_blank"> {props?.data?.Linkedin.replace("https://www.linkedin.com", "")}</a></span></li>
                                    </ul>
                                </div>
                            </React.Fragment>}
                            {props?.data?.Instagram && <React.Fragment>
                                <div className="col-lg-4 col-5">
                                    <ul className="desc-list pt-0">
                                        <li><span className="txt-desc txt-desc-text">Instagram:</span></li>
                                    </ul>
                                </div>
                                <div className="col-lg-7 col-7">
                                    <ul className="desc-list pt-0">
                                        <li><span className="desc-value"><a href={props?.data?.Instagram} target="_blank"> {props?.data?.Instagram.replace("https://instagram.com", "")}</a></span></li>
                                    </ul>
                                </div>
                            </React.Fragment>}
                        
                        </div>}
                        {props?.data?.About && <div className="mt-4">
                            {HTMLReactParser(props?.data?.About)}
                        </div> }
                        
                    </Col>
                </Row>
            </Container>
            <Modal size="lg" show={emailModal} onHide={() => setEmailModal(false)} centered className="contact-form-sec">
                <div className="contact-form subsribe-form">
                    <Modal.Header closeButton>
                        <Modal.Title as="h2">Contact {props?.data?.Name}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ContactFormPage
                            email={props?.data?.Email}
                            formname="Person Contact"
                        />
                    </Modal.Body>
                </div>
            </Modal>
        </div>
    )
}

export default TeamDetail;